@import "../../vars.scss";

.navigation {
  width: 100%;
  overflow: hidden;

  nav {
    min-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $black;
    font-size: 20px;  
    font-family: 'Montserrat';

    img {
      padding: 0 15px;
    }
  }
  
  $border: 2px solid white;

  a {
    font-size: 20px;
    text-decoration: none;
    color: white;

    &:nth-last-of-type(1){
      border-right: unset;
    }
  }

  &-photo {
    display: none;
    width: 100%;
    max-height: 420px;
  }

  &.transparent {

    position: relative;

    nav {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
    }

    .navigation-photo {
      display: block;
    }
  }

  &.white {
    nav {
      background: unset;
    }
    
    a {
      color: $dark-gray;
      border-color: $dark-gray;
      opacity: 0.6;
    }
  }

  @media (max-width: $laptop-screen-size) {
    nav {
      flex-wrap: wrap;

      a {
        padding: 5px 0;
      }
    }

    .navigation-photo {
      width: 200%;
      margin-left: -50%;
    }
  }

  @media (max-width: $small-phones-screen-size) {
    margin-top: 56px;
    nav {
      display: none;
    }
  }
}

.mobile-navigation {

  @media (min-width: $small-phones-screen-size) {
    display: none;
  }

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &.active {
    height: 100%;
  }

  .menu {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    box-sizing: border-box;

    &.active {
      z-index: 10;
    }
  }

  .title {
    height: 48px;
    width: 100%;
    background-color: $blue;

    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;

    position: relative;
    z-index: 10;

    %icon {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 48px;
      height: 48px;
      cursor: pointer;
    }

    .open-icon {
      @extend %icon;
      background-image: url(../../assets/icons/open.png);  
    }

    .close-icon {
      @extend %icon;
      background-image: url(../../assets/icons/close.png);  
    }
  }

  .logo {
    img {
      width: 112px;
    }
  }

  .next-school {
    text-align: left;
    align-self: flex-start;
    padding: 0 24px;

    .text {
      color: $dark-gray;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 145%;
    }

    .date {
      color: $black;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 115%;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;

    a {
      font-family: "Montserrat";
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
      text-decoration: none;
      color: $black;
      margin-bottom: 18px;

      &:hover {
        color: $blue;
      }
    }
  }

  button {
    margin-bottom: 55px;
    font-size: 16px;
    min-width: 255px;
  }
}