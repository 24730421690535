@import "../../vars.scss";

.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;

  & > div {
    // width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 25px 0;

    & > h1,
    & > p {
      margin: 0 15px;
    }
  }

  @media (max-width: $phones-screen-size) {
    & > div {
      width: unset;
    }
  }

}