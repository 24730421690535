@import "../../vars.scss";

.feedbacks {

  .title {
    span {
      font-weight: bold;
      color: $blue;
    }
  }

  .social {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      opacity: 0.6;
      margin: 0 24px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        transform: scale(1.1);
      }

      img {
        width: 56px;
        height: 56px; 
      }
    }
  }

  &-list {

    &-feedback {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;

      .text {
        width: 70%;
        padding-left: 16px;
        line-height: 145%;

        .title {
          h3 {
            line-height: 36px;
          }
        }
        
        h3 {
          margin-bottom: 8px;
        }

        h4 {
          font-family: 'Noto Sans';
          font-weight: bold;
          font-size: 15px;
          color: $dark-gray;
        }

        .description {
          font-size: 20px;
        }
      }

      .photo {
        height: 303px;
        width: 30%;
        min-width: 224px;

        background-image: url(../../assets/author-eremin.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 8px;
      }

      h3 {
        margin: 0;
      }

      h4 {
        color: $vera-blue;
        margin: 0;
      }

      .description {
        height: 100%;
      }

      .details {
        text-align: center;
        .button {
          width: unset;
        }
      }
      
    }
  }

  @media (max-width: $small-phones-screen-size) {

    &-list {
      &-feedback {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .text {
          width: unset;
        }

        .photo {
          margin-bottom: 40px;
        }

      }
    }

  }

  @media (max-width: $phones-screen-size) {

    &-list {
      &-feedback {
        // flex-direction: column;
      }
    }

  }
}


