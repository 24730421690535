@import "./vars.scss";
@import "./other.scss";

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
}

body {
  margin: 0;
  font-family: Noto Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  font-weight: 300;
  color: #777b8b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  box-sizing: border-box;
  font-weight: bold;
  font-family: "Montserrat";
}

h1 {
  font-size: 50px;
  color: $blue;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 26px;
  font-weight: 600;
}

h5 {
  font-size: 20px;
  font-weight: 500;
}

.bold {
  font-weight: bold;
  font-size: 20px;
}

.primary {
  width: 70%;
  margin: auto;

  @media (max-width: $phones-screen-size) {
    width: 90%;
  }
}

.row {
  padding: 32px 120px;
  max-width: 1140px;
  margin: auto;
  color: $black;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  box-sizing: border-box;

  &.background {
    max-width: unset;
    padding: unset;
    background-color: $white-blue;

    & .content {
      padding: 32px 120px;
      max-width: 1140px;
      margin: auto;
      box-sizing: border-box;
    }
  }

  &.lead {
    font-size: 22px;
  }

  @media (max-width: $phones-screen-size) {
    padding-left: 25px;
    padding-right: 25px;

    &.background {
      & .content {
        padding-left: 25px;
        padding-right: 25px;
      }
    }

    font-size: 16px;
    &.lead {
      font-size: 18px;
    }

    h1 {
      font-size: 36px;
    }
  }
}

[role="button"],
a,
area,
button,
input:not([type="range"]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  vertical-align: middle;
  border-style: none;
}
