@import "../../vars.scss";


.school {

  &-videos {

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &-video {
      width: 48%;
      margin: 25px 0;
  
      &-item {
        position: relative;
        padding-bottom: 55.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
        max-width: 960px;
        /* height: 500px; */
      }
  
      &-item iframe,
      &-item object,
      &-item embed {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
      }

      a {
        text-transform: none;
        text-decoration: none;
        color: black;
      }
    }
    
  }

  @media (max-width: $phones-screen-size) {

    h1 {
      font-size: 44px;
      text-align: center;
    }

    &-videos {

      justify-content: center;
  
      &-video {
        width: 90%;
      }
    }
  }  
}