@import "../../vars.scss";

.main {
  text-align: center;
  padding: 25px;
  box-sizing: border-box;

  @media (max-width: $phones-screen-size) {
    button {
      width: 60%;
    }
  }  
}