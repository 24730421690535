@import "../../vars.scss";

.notFound {
  text-align: center;

  p {
    font-size: 20px;
  }

  @media (max-width: $phones-screen-size) {
    width: 90%;
    margin: auto;
    margin-bottom: 40px;

    h1 {
      font-size: 40px;
    }

    .button {
      width: unset;
    }
  }
}