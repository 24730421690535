@import "../../vars.scss";

.button {
  border: 0;
  color: $white;
  background-color: $blue;
  padding: 16px 40px;
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: $blue-hover;
  }

  &.disabled {
    background-color: $vera-gray;
    cursor: not-allowed;
  }

  @media (max-width: $small-phones-screen-size) {
    font-size: 16px;
  }
}