@import "../../vars.scss";

.registration {

  button[type=submit] {
    width: 100%;
  }

  .attachments {
    h4 {
      margin-bottom: 33px;
    }

    .form-field {
      &.text {
        padding-top: unset;
        color: $black;
      }

      textarea {
        height: 172px;
        resize: none;
      }

      &.textarea-wrapper {
        position: relative;
        .count {
          position: absolute;
          top: 130px;
          right: 25px;
          color: $light-blue;
        }
      }
    }
  }

  .form-subtitle {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum","tnum";
    margin: 0 0 24px;

  }

  .form-row {
    display: flex;
    margin-bottom: 42px;

    &:last-child {
      margin-bottom: unset;
    }
  }

  .form-field {
    padding-left: 16px;
    box-sizing: border-box;

    &:first-child {
      padding-left: unset;
    }

    &.width-60 {
      width: 60%;
    }

    &.width-40 {
      width: 40%;
    }

    &.width-20 {
      width: 20%;
    }

    input, select, option, textarea {
      height: 56px;
      background: $white;
      border: 2px solid $light-blue;
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;

      font-family: "Noto Sans";
      font-size: 20px;
      line-height: 145%;
      color: $black;
      padding: 0 14px;

      &::placeholder {
        color: $light-blue;
      }

      &:focus {
        outline: 0;
        outline-width: 0;
      }
    }

    select.empty {
      color: $light-blue;
    }

    textarea {
      min-height: 114px;
      padding-top: 14px;
    }

    &.text {
      font-size: 15px;
      line-height: 145%;
      color: $dark-gray;
      padding-top: 7px;
    }

    &.reminder {
      background-color: $white-blue;
      min-height: 92px;
      font-size: 15px;
      line-height: 145%;
      color: $blue;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      span {
        max-width: 64%;
      }
    }

  }
  
  .upload .btn-tertiary {
    margin:auto;
    display:block;
  }
  
  .upload .input-file {
    width:.1px;
    height:.1px;
    opacity:0;
    overflow:hidden;
    position:absolute;
    z-index:-1
  }
  
  .upload .input-file + .js-labelFile {
    text-overflow:ellipsis;
    white-space:nowrap;
    cursor:pointer
  }
  
  .upload .input-file + .js-labelFile .icon:before {
    content:"\f093"
  }
  
  .upload .input-file + .js-labelFile.has-file .icon:before { 
    content:"\f00c";
    color:#5AAC7B
  }
  
  .upload {
    display: inline-flex;
    align-items: center;

    label {
      border: 0;
      color: $white;
      background: $blue;
      padding: 16px 40px;
      font-family: 'Montserrat';
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: $blue-hover;
      }
    }

    & > span {
      margin-left: 16px;
      .filename {
        color: $blue;
        font-weight: bold;
      }
    }
  }

  .checkbox {
    h5 {
      color: #242527;
    }

    /* Customize the label (the container) */
    .container {
      display: inline-block;
      position: relative;
      padding-left: 40px;
      margin-top: 20px;
      margin-right: 46px;
      cursor: pointer;
      font-size: 20px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.other {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        input[type=text] {
          margin: 0 15px;
        }
      }
    }

    /* Hide the browser's default radio button */
    .container input[type="radio"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      border: 2px solid $dark-gray;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: $dark-gray;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: white;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      left: 29.9%;
      bottom: 29.9%;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: $dark-gray;
    }
  }
  
  @media (max-width: $small-phones-screen-size) {
    font-size: 14px;

    .upload {
      flex-direction: column;

      & > span {
        margin-top: 16px;
        margin-left: 0;
      }
    }

    .form-row {
      flex-wrap: wrap;

      &.with-text {
        flex-direction: column-reverse;

        .text {
          padding-bottom: 20px;
          padding-left: 0;
          padding-top: 0;
          width: 100%;
        }
      }

      &:last-child {
        margin-bottom: 42px;
      }
    }

    .form-field {
      &.width-60 {
        width: 100%;
      }
  
      &.width-40 {
        width: 50%;
      }
  
      &.width-20 {
        width: 50%;
      }

      &.other-input-field {
        width: 100%;
        margin-top: 42px;
        padding-left: 0;
      }

      input, select, option, textarea {
        font-size: 16px;
  
        &::placeholder {
          font-size: 16px;
        }
      }
    }

    .reminder-row {
      margin-left: -25px;
      margin-right: -25px;

      .reminder {
        span {
          max-width: 80%;
        }
      }
    }
  }

  @media (max-width: $phones-screen-size) {
    h1 {
      font-size: 40px;
      text-align: center;
    }

    .button {
      width: unset;
    }

    .form-field {
      width: unset;
    }
  }
  
}