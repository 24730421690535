@import "../../vars.scss";

.footer {

  background-color: $light-gray;
  box-sizing: border-box;
  padding: 40px 0 70px;
  margin-top: 72px;

  &-contacts,
  &-share {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $vera-gray;
    padding: 12px;
  }

  &-contacts {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 20px;
    color: $dark-gray;
    opacity: 0.6;

    div {
      padding: 0 15px;
    }
  }

  &-share {
    font-size: 22px;
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      cursor: pointer;
      padding: 0 15px;
      height: 24px;
      width: 24px;
      opacity: 0.6;
    }

    &-group.aids:nth-child(1) {
      display: none;
    }

    .aids, .twitter {
      img {
        height: unset;
        width: unset;
      }
    }
}

  @media (max-width: $phones-screen-size) {
    width: 100%;
    margin: unset;

    .navigation {
      display: none;
    }

    &-share {
      flex-wrap: wrap;
      // flex-direction: column;

      img {
        padding: 10px 15px;
      }

      &-group.aids:nth-child(1) {
        display: block;
        width: 100%;
        text-align: center;
      }

      &-group.aids:nth-child(3) {
        display: none;
      }

    }

    &-contacts {
      flex-direction: column;
      div {
        padding: 10px 0;
      }
    }
  }
}