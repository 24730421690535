$vera-blue: rgb(24, 44, 84);
$vera-gray: rgb(149, 149, 149);

$dark-gray: #777B8B;
$light-gray: #F0F2F5;
$dark-blue: #1B2C54;
$blue: #33549F;
$blue-hover: #2D4A8B;
$light-blue: #8EA5DB;
$white-blue: #F1F4FB;
$black: #212121;
$white: #FFFFFF;

$laptop-screen-size: 1024px;
$phones-screen-size: 768px;
$small-phones-screen-size: 480px;
