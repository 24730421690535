@import "../../vars.scss";


.lesson {
  
  &-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    max-width: 1280px;
    /* height: 500px; */
    margin: auto;
  }
  
  &-video iframe,
  &-video object,
  &-video embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }

  .blockquote {
    margin: 40px 0;
  }

  li {
    margin-bottom: 15px;
  }

  @media (max-width: $phones-screen-size) {
    h1 {
      font-size: 40px;
      text-align: center;
    }
  }
}