@import "../../vars.scss";

.blockquote {
  margin: 0;
  text-align: justify;
  color: $vera-blue;
  font-weight: 500;
  position: relative;
  background-color: $white-blue;

  &-text {
    font-size: 22px;
    color: $black;
    margin-bottom: 16px;

    @media (max-width: $small-phones-screen-size) {
      font-size: 18px;
    }
  }

  &-author {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 20px;
    color: $black;

    @media (max-width: $small-phones-screen-size) {
      font-size: 16px;
    }

    img {
      width: 64px;
      height: 64px;
      margin-right: 16px;
      border-radius: 64px;
    }

    &-desc {
      font-size: 15px;
      color: $dark-gray;

      @media (max-width: $small-phones-screen-size) {
        font-size: 14px;
      }
    }
  }

  &-row {
    display: flex;
    justify-content: space-between;

    @media (max-width: $small-phones-screen-size) {
      flex-direction: column;
    }
  }

  .icon {
    width: 80px;
    height: 48px;
    margin-right: 16px;
    margin-bottom: 16px;
    background: url(../../assets/icons/blockquote.png) no-repeat;
  }

  .content {
    width: 100%;
  }

}