@import "../../vars.scss";

.feedback {

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .photo {
      
      // width: 35%;
      width: 320px;
      min-width: 260px;

      img {
        width: 90%;
      }
    }

    .description {
      width: 66%;
      h1 {
        margin: 0;
      }
    }
  }

  .content {
    li {
      margin-bottom: 15px;
    }
  }

  @media (max-width: $phones-screen-size) {
    
    h1 {
      font-size: 44px;
      text-align: center;
    }

    .header {
      flex-wrap: wrap;

      .photo {
        min-width: unset;
        width: unset;
        margin: auto;
      }

      .description {
        width: unset;
      }
    }
  }
}