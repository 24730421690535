@import "../../vars.scss";

.formFilled {
  text-align: center;

  h1 {
    text-align: center;
    margin: 40px auto;    
    max-width: 800px;
  }

  @media (max-width: $phones-screen-size) {
    width: 90%;
    margin: auto;
    margin-bottom: 40px;

    h1 {
      font-size: 40px;
    }

    .button {
      width: unset;
    }
  }
}