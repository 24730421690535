@import "../../vars.scss";

.header {
  margin-bottom: 40px;

  &-title {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    width: 100%;
    font-family: "Montserrat";

    img {
      margin: 24px;
      width: 112px;

      @media (max-width: $phones-screen-size) {
        margin: 24px 0;
      }
    }

    @media (max-width: $small-phones-screen-size) {
      display: none;
    }
  }

  &-other-logo {
    position: absolute;
    top: 45px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: 0 !important;
    width: 180px !important;
  }

  &-img-link {
    pointer-events: initial;
  }

  &-link {
    position: relative;
    background: $blue;
    color: $white;
    width: 35%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;

    /*\ enable when open
          &:hover {
      background: $blue-hover;
    }
      */

    $border-radius: 45px;

    &:nth-of-type(1) {
      border-bottom-right-radius: $border-radius;
    }

    &:nth-of-type(2) {
      border-bottom-left-radius: $border-radius;
    }

    a {
      text-decoration: none;
      color: $white;
    }

    &.registration {
      position: relative;

      .next-school {
        position: absolute;
        top: 72px;
        right: 100px;
        text-align: right;

        .text {
          color: $dark-gray;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 145%;
        }

        .date {
          color: $black;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 26px;
          line-height: 115%;
        }
      }
    }
  }

  @media (max-width: $phones-screen-size) {
    margin-bottom: 0;

    &-link {
      height: unset;
      min-height: 40px;
      max-height: 60px;
      font-size: 16px;

      &.registration {
        .next-school {
          right: 40px;

          .text {
            font-size: 18px;
          }

          .date {
            font-size: 20px;
          }
        }
      }
    }

    &-title {
      img {
        margin: 24px 0;
      }
    }
  }

  @media (max-width: $small-phones-screen-size) {
    &-link {
      &.registration {
        .next-school {
          right: 10px;

          .text {
            font-size: 14px;
          }

          .date {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.granty {
  display: flex;
  left: 30%;
  top: 80px;
  gap: 15px;

  @media screen and (max-width: 768px) {
    gap: 10px;
    left: 45%;
  }


  .komitet-img {
    width: 150px;
    height: 50px;
    margin: 0;

    @media screen and (max-width: 768px) {
      width: 90px;
      height: 37px;
    }
  }

  .granty-msk-img {
    margin: 0;
    width: 150px;
    height: 50px;

    @media screen and (max-width: 768px) {
      width: 90px;
      height: 37px;
    }
  }
}