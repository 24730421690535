@import "../../vars.scss";

.contacts {

  &-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & > div {
      width: 25%;
      text-align: center;
    }

  }

  &-map {
    // width: 100%;
    height: 400px;

    .map-marker {
      position: relative;

      $iconSize: 40px;

      img {
        position: absolute;
        top: -$iconSize;
        left: -$iconSize/2;
        height: $iconSize;
        width: $iconSize;
      }
    }
  }

  @media (max-width: $phones-screen-size) {

    h1 {
      font-size: 40px;
      text-align: center;
    }

    .button {
      width: unset;
    }
    
    &-info {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  @media (max-width: $small-phones-screen-size) {
    &-info {

      & > div {
        width: 90%;
      }
    }
  }
}