@import "../../vars.scss";

.participants {

  &-gallery {
    margin: 25px 0;
  }

  &-registration {
    text-align: center;
  }

  @media (max-width: $phones-screen-size) {

    &-gallery {
      width: 85%;
      margin: 25px auto;
    }

    h1 {
      font-size: 44px;
    }
  }
}