@import "../../vars.scss";

.about {
  .lead {
    position: relative;
  }

  .next-school {
    position: absolute;
    top: 33px;
    right: 33px;
    background-color: #33549f;
    border-radius: 50px;
    padding: 5px 30px;
    text-align: center;

    .date {
      font-weight: 700;
    }

    * {
      color: #fff;
    }

    @media (max-width: $laptop-screen-size) {
      display: block;
      position: static;
      max-width: 310px;
      margin: 0 0 40px;
    }
  }

  &-photo {
    width: 100%;
  }

  &-video {
    display: flex;
  }

  &-video-wrapper {
    position: relative;
    padding-bottom: 32.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    max-width: 960px;
    /* height: 500px; */
    width: 100%;
    margin-right: 25px;
  }

  &-video-wrapper iframe,
  &-video-wrapper object,
  &-video-wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-video-desc {
    width: 50%;
  }

  @media (max-width: $phones-screen-size) {
    margin-bottom: 40px;

    h1 {
      font-size: 44px;
    }

    &-video {
      flex-direction: column;

      &-desc {
        color: $dark-gray;
        font-size: 14px;
        line-height: 22px;
        padding-top: 20px;
      }
    }

    &-video-wrapper {
      padding-bottom: 52.25%;
    }

    &-video-desc {
      width: 100%;
    }
  }

  @media (max-width: $small-phones-screen-size) {
    h1 {
      font-size: 36px;
    }
  }
}
