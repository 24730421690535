@import "../../vars.scss";

.feedback-form {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;

  .form-field {
    width: 45%;
    margin-top: 40px;
  }

  label {
    display: block;
    color: $blue;
    font-weight: 700;
  }

  input {
    border: 0;
    border-bottom: 2px solid $light-gray;
    margin-top: 10px;
    height: 35px;
    width: 100%;
    opacity: 0.5;

    &:focus {
      outline: 0;
      outline-width: 0;
    }
  }

  @media (max-width: $phones-screen-size) {
    .form-field {
      width: 90%;
      margin-bottom: 40px;

    }
  }  
}