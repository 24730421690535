@import "../../vars.scss";

.gallery {
  position: relative;

  .row {
    position: relative; 
  }

  &-list {
    .page {
      display: none;

      .item {

        .photo {
          img {
            border-radius: 8px;
            height: 100%;
            width: 100%;
  
            &.with-content {
              border-bottom-left-radius: 0; 
              border-bottom-right-radius: 0; 
            }
          }
        }

        .content {
          padding: 24px;
          margin-top: -5px;
          min-height: 250px;
          background-color: $white-blue;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-bottom-left-radius: 8px; 
          border-bottom-right-radius: 8px; 

          .date {
            font-size: 15px;
            line-height: 145%;
            color: $dark-gray;
          }

          .title {
            font-family: "Montserrat";
            font-weight: bold;
            font-size: 36px;
            line-height: 115%;
            color: $black;
          }

          .desc {
            font-size: 15px;
            line-height: 145%;
            color: $black;
          }

          .link a {
            font-weight: bold;
            font-size: 15px;
            line-height: 145%;
            color: $blue;
            text-decoration: none;

            img {
              margin-left: 5px;
            }
          }
        }

      }

      &.active {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  %arrow {
    position: absolute;
    top: 45%;
    width: 48px;
    height: 48px;
    background: url(../../assets/icons/arrow.png) no-repeat;
    cursor: pointer;
    transition: all .2s ease-in-out;
  }

  .left {
    @extend %arrow;
    left: 58px;
    transform: rotate(180deg);

    &:hover {
      transform: scale(1.1) rotate(180deg);
    }
  }

  .right {
    @extend %arrow;
    right: 58px;

    &:hover {
      transform: scale(1.1);
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ellipse {
    background: $dark-gray;
    width: 8px;
    height: 8px;
    margin: 16px 8px;
    border-radius: 8px;
    opacity: 0.3;
    cursor: pointer;

    &:hover {
      opacity: unset;
    }

    &.active {
      background: $light-blue;
      opacity: unset;
    }
  }

  .desc {
    font-size: 15px;
    color: $dark-gray;
    
    &.row {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @media (max-width: $phones-screen-size) {    
    & > .row {
      padding-left: 0;
      padding-right: 0;
    }

    .left,
    .right {
      display: none;
    }

    &-list {
      .page {
        .item {
          .photo img,
          .content {
            border-radius: 0;
          }
        }
      }
    }
  }

  @media (min-width: $phones-screen-size) {    
    .desc.row {
      padding-left: 0;
      padding-right: 0;
    }
  }

}
